import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static finixForm = null
  static finixAuth = null

  static values = {
    env: String,
    applicationId: String,
    merchantId: String
  }

  connect() {
    this.initFinix()
  }

  disconnect() {}

  initFinix() {
    const options = this.getFinixFormOptions()

    this.finixAuth = window.Finix.Auth(this.envValue, this.merchantIdValue)
    this.finixForm = window.Finix.CardTokenForm("finix-form-element", options)
  }

  submit(event, callback) {
    event.preventDefault() // Prevent the form from submitting immediately

    if (!this.finixForm) {
      alert("Finix is not initialized yet!")
      return
    }

    this.finixForm.submit(this.envValue, this.applicationIdValue, (err, res) => {
      if (err) {
        console.error("Finix submission error:", err)
        callback(false)
      }

      const token = res.data?.id
      if (token) {
        $("#payment_method_type").val("credit_card")
        $("#payment_method_nonce").val(token)
        $("#payment_session_id").val(this.finixAuth.getSessionKey())

        callback(true)
      }
    })
  }

  getFinixFormOptions() {
    return {
      showAddress: false,
      showLabels: true,
      labels: { name: "Cardholder Name" },
      showPlaceholders: true,
      placeholders: { name: "Cardholder Name" },
      hideFields: [
        "address_line1", "address_line2", "address_city",
        "address_region", "address_state", "address_country", "address_postal_code"
      ],
      requiredFields: ["name"],
      hideErrorMessages: false,
      errorMessages: { name: "Please enter a valid name" },
      styles: {
        default: {
          color: "#000",
          border: "1px solid #CCCDCF",
          borderRadius: "8px",
          padding: "8px 16px",
          fontFamily: "Helvetica",
          fontSize: "16px",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)",
        },
        error: { border: "1px solid rgba(255,0,0, 0.3)" },
      },
      onUpdate: (state, binInformation, formHasErrors) => {
        if (formHasErrors) {
          this.disableCCSubmitButton()
        } else {
          this.enableCCSubmitButton()
        }
      },
      onLoad: () => {
        $("#finix-form-element #spinner").hide()
      },
    }
  }

  enableCCSubmitButton() {
    // Check if either id exists cc_submit_btn or register_btn
    // Then disable 1 of them
    if ($("#cc_submit_btn").length) {
      $("#cc_submit_btn").removeAttr("disabled")
    } else {
      $("#register_btn").removeAttr("disabled")
    }
  }

  disableCCSubmitButton() {
    // Check if either id exists cc_submit_btn or register_btn
    // Then disable 1 of them
    if ($("#cc_submit_btn").length) {
      $("#cc_submit_btn").attr("disabled", "disabled")
    } else {
      $("#register_btn").attr("disabled", "disabled")
    }
  }
}
