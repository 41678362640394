import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
import "select2"
import { EMAIL_REGEX, FORM_VALIDATION_EMAIL_REGEX, NAME_REGEXP, shiftYears } from "../../utils/common"
import moment from "moment"

export default class extends Controller {
  static targets = [ "direction" ]
  static values = { validateOnConnect: Boolean, default: false }

  connect () {
    this.runConnect()
    this.clearErrorsAfterTyping()
  }

  disconnect () {
    if (window.customerFormValidation) {
      window.customerFormValidation.destroy()
    }
  }

  // Private

  runConnect () {
    const validateEmail = debounce((email, resolve, reject) => {
      fetch("/validator/validate_email", {
        method: "POST",
        headers:  {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({ email })
      }).then(response => response.json()).then(result => {
        resolve({
          message: "Customer with this email already exists",
          meta: result,
          valid: result.valid
        })
      }).catch(error => {
        reject({
          valid: false,
          message: "Failed to validate email",
          meta: {}
        })
      })
    }, 300)

    // https://3.basecamp.com/3611527/buckets/21056952/card_tables/cards/7378844569
    function validateBirthdate(value) {
      const birthdate = new moment("01/" + value, "DD/MM/YYYY", true)
      if (!birthdate.isValid()) {
        return false
      }
      return birthdate.isAfter(shiftYears(150)) && birthdate.isBefore(shiftYears(10))
    }

    const customerForm = $(this.element)
    const addressValidationSkipControl = $("#address-validation-controls")
    const isCreditCard = () => ( ["credit_card", ""].includes($("#payment_method_type").val()) )

    // TODO: Why these validations are not called on FE and goes directly to BE for validation?
    const form = this.element
    const pluginsAndFields = {
      plugins: {
        bootstrap: new FormValidation.plugins.Bootstrap(), // eslint-disable-line
      },
      fields: {
        "customer[first_name]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid name",
              callback: (input) => (!input.value || NAME_REGEXP.test(input.value))
            }
          },
        },
        "customer[last_name]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid name",
              callback: (input) => (!input.value || NAME_REGEXP.test(input.value))
            }
          },
        },
        "customer[email]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
            callback: {
              message: "The input is not a valid email address",
              callback: (input) => (!input.value ||
                (EMAIL_REGEX.test(input.value) && FORM_VALIDATION_EMAIL_REGEX.test(input.value)))
            },
            promise: {
              promise: (input) => {
                return new Promise((resolve, reject) => {
                  if (input.value === "" || input.value === null) {
                    resolve({
                      valid: true
                    })

                    return
                  }

                  validateEmail(input.value, resolve, reject)
                })
              }
            }
          },
        },
        "customer[birthdate]": {
          validators: {
            notEmpty: {
              message: "Required"
            },
            regexp: {
              regexp: "^(0[1-9]|10|11|12)/[1-2][0-9]{3}$",
              message: "Invalid format"
            },
            callback: {
              message: "Hmm, your birthday doesn't seem right, please enter an accurate date",
              callback: (input) => (!input.value || validateBirthdate(input.value))
            }
          },
        },
        "customer[phone]": {
          validators: {
            notEmpty: {
              message: "Required"
            },
            phone: {
              country: "US",
              message: "Invalid phone number",
            },
            callback: {
              message: "Invalid phone number",
              callback: (input) => (input.value.length == 0 || input.value.replace(/[^0-9]/g, "").length === 10)
            },
          },
        },
        "customer[customer_style][top_size]": {
          excluded: false,
          validators: {
            notEmpty: {
              message: "Please choose your Top Size",
            },
          },
        },
        "customer[customer_style][bottom_size]": {
          excluded: false,
          validators: {
            notEmpty: {
              message: "Please choose your Bottom Size",
            },
          },
        },
        "customer[shipping_address][street]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "customer[shipping_address][city]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "customer[shipping_address][state_id]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "customer[shipping_address][zip]": {
          validators: {
            notEmpty: {
              message: "Required",
            },
          },
        },
        "customer[billing_address][street]": {
          validators: {
            callback: {
              message: "Required",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            }
          },
        },
        "customer[billing_address][city]": {
          validators: {
            callback: {
              message: "Required",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            }
          },
        },
        "customer[billing_address][state_id]": {
          validators: {
            callback: {
              message: "Required",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            }
          },
        },
        "customer[billing_address][zip]": {
          validators: {
            callback: {
              message: "Required",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            }
          },
        },
        "customer[preferences][cardholder_name]": {
          validators: {
            callback: {
              message: "Required",
              callback: (input) => ( !isCreditCard() || input.value.replace(/\s/g,"").length > 0 )
            },
          },
        },
        "customerz[customer_style][hated_colors][]": {
          excluded: false,
          validators: {
            callback: {
              message: "Please limit selection to only 6 colors you dislike.",
              callback: function (input) {
                return (
                  $(
                    'input[name="customer[customer_style][hated_colors][]"]:checked'
                  ).length < 7
                )
              },
            },
          },
        },
      },
    }

    const formValidation = FormValidation.formValidation(form, pluginsAndFields) // eslint-disable-line

    formValidation.on("core.field.invalid", function (field) {
      // console.log("core.field.invalid " + field)

      if (field === "customer[shipping_address][state_id]") {
        $("span.select2-container").removeClass("is-valid")
        $("span.select2-container").addClass("is-invalid")
        $("div.customer_shipping_address_state_id").addClass("form-group-invalid")
        $("div.customer_shipping_address_state_id").removeClass("form-group-invalid")
      }
      if (field === "customer[billing_address][state_id]") {
        $("span.select2-container").removeClass("is-valid")
        $("span.select2-container").addClass("is-invalid")
        $("div.customer_billing_address_state_id").addClass("form-group-invalid")
        $("div.customer_billing_address_state_id").removeClass("form-group-invalid")
      }
    })

    formValidation.on("core.field.valid", function (field) {
      // console.log("core.field.valid " + field)

      if (field === "customer[shipping_address][state_id]") {
        $("span.select2-container").removeClass("is-invalid")
        $("span.select2-container").addClass("is-valid")
        $("div.customer_shipping_address_state_id").addClass("form-group-valid")
        $("div.customer_shipping_address_state_id").removeClass("form-group-invalid")
      }
      if (field === "customer[billing_address][state_id]") {
        $("span.select2-container").removeClass("is-invalid")
        $("span.select2-container").addClass("is-valid")
        $("div.customer_billing_address_state_id").addClass("form-group-valid")
        $("div.customer_billing_address_state_id").removeClass("form-group-invalid")
      }
    })

    customerForm.on("change", 'input[name="customer[shipping_address][street]"], input[name="customer[shipping_address][city]"], [name="customer[shipping_address][state_id]"], input[name="customer[shipping_address][zip]"]', // eslint-disable-line
      function (e) {
        const street = $('input[name="customer[shipping_address][street]"]').val()
        const city = $('input[name="customer[shipping_address][city]"]').val()
        const stateId = $('select[name="customer[shipping_address][state_id]"]').val()
        const zip = $('input[name="customer[shipping_address][zip]"]').val()

        if (
          street.length > 0 &&
          city.length > 0 &&
          stateId.length > 0 &&
          zip.length > 0
        ) {
          $(".address-error").hide()

          addressValidationSkipControl.hide()
        }
      }
    )

    // TODO: is this still relevant?
    // Button must have text content according to accessibility requirements
    // see https://www.w3.org/TR/WCAG20/#text-equiv
    customerForm.find(":submit.fv-hidden-submit").text("Submit")

    // Make the formValidation global so it can be used from elsewhere
    window.customerFormValidation = formValidation

    $(".js-select2").select2({
      placeholder: "State"
    })
    $(".js-select2").on("select2:select", function (e) {
      formValidation.revalidateField("customer[shipping_address][state_id]")
      if ($("input[name='customer[billing_address][state_id]']").length) {
        formValidation.revalidateField("customer[billing_address][state_id]")
      }
      $(".select2-state_id-label").show()
    })
    $("span.select2-container").addClass("form-control")

    if ($(".js-select2").hasClass("is-invalid")) {
      $("span.select2-container").addClass("is-invalid")
    }
    if ($(".js-select2").hasClass("is-valid")) {
      $("span.select2-container").addClass("is-valid")
    }

    $(document).on("focus", ".select2.select2-container", function (e) {
      // if this element"s sibling is a select with id of customer_shipping_address_state_id continue
      const enabledSelect = $(this).siblings("select:enabled").attr("id")
      const isStateSelectElement =  enabledSelect === "customer_shipping_address_state_id"
      if (!isStateSelectElement) {
        return
      }

      // don"t re-open on closing focus event
      const isOriginalEvent = e.originalEvent

      // multi-select will pass focus to input
      const isSingleSelect = $(this).find(".select2-selection--single").length > 0

      if (isOriginalEvent && isSingleSelect) {
        $(this).siblings("select:enabled").select2("open")
      }
    })

    // Autocompletion for birthdate field
    function birthdateAutoComplete(evt) {
      const v = this.value
      if (v.match(/^\d{2}$/) != null) {
        if (evt.keyCode != 8 && evt.keyCode != 46) {
          this.value = v + "/"
        }
      }
    }

    function phoneNumberAutoComplete(evt) {
      // Prevent entry of more numbers once the phone number is complete.
      const phoneNumber = this.value.replace(/\D/g, "")
      if (phoneNumber.length > 10) {
        evt.preventDefault()
      }

      this.value = formatPhoneNumber(phoneNumber)
    }

    function formatPhoneNumber(phoneNumber) {
      const characterPosition = { 0: "(", 3: ") ", 6: "-" }

      let formattedPhone = ""
      for (let i = 0; i < phoneNumber.length; i++) {
        formattedPhone += (characterPosition[i] || "") + phoneNumber[i]
      }

      return formattedPhone
    }

    if ($("input.phone").length) {
      $("input.phone").val(formatPhoneNumber($("input.phone").val().replace(/\D/, "")))
    }

    // TODO: move into Stimulus controllers and attach to specific fields
    $("input.birthdate").on("keyup",birthdateAutoComplete)
    $("input.phone").on("keyup", phoneNumberAutoComplete)

    $("input.cardholder_name").on("keyup", function () {
      window.customerFormValidation.revalidateField("customer[preferences][cardholder_name]")
    })

    if (this.validateOnConnectValue) {
      setTimeout(() => this.revalidateFields(), 300)
    }
  }

  next(event) {
    event.preventDefault()

    const button = event.currentTarget
    button.setAttribute("disabled", true)
    button.value = "Saving ..."

    const form = this.element

    // Show errors if any and only request submit if all form validations pass.
    window.customerFormValidation.validate().then(status => {
      if (status == "Valid") {

        const paymentForm = document.querySelector("[data-controller='payment-form-finix']")
        if (paymentForm) {
          const paymentController = this.application.getControllerForElementAndIdentifier(
            paymentForm, "payment-form-finix"
          )
          paymentController.submit(event, function(success) {
            if (success) {
              form.requestSubmit()
            } else {
              alert("Update failed. Please try again.")
              button.removeAttribute("disabled")
              button.value = "Save"
            }
          })
        } else {
          form.requestSubmit()
        }
      }
    })
  }

  prev(event) {
    // console.log("Signup Quiz Prev")
    event.preventDefault()
    $(this.directionTarget).val("prev")
    this.element.requestSubmit()
  }

  revalidateFields() {
    if ($("#customer_shipping_address_state_id").length) {
      // console.log("customer_form_controller revalidateFields")

      window.customerFormValidation.validate()
    }
  }

  clearErrorsAfterTyping() {
    // clear all errors when a field is changed
    const formElementSelectors = [
      "customer[first_name]",
      "customer[last_name]",
      "customer[birthdate]",
      "customer[email]",
      "customer[phone]",
      "customer[shipping_address][street]",
      "customer[shipping_address][city]",
      "customer[shipping_address][state_id]",
      "customer[shipping_address][zip]"
    ]

    formElementSelectors.forEach(selector => {
      const inputSelector = `[name='${selector}']`
      const el = $(inputSelector)

      let triggerEvent = "input"
      let elementWithErrors = el

      if (el.is("select")) {
        triggerEvent = "change"
        elementWithErrors = el.siblings(".select2")
      }

      el.on(triggerEvent, function () {
        if (elementWithErrors.hasClass("is-invalid")) {
          elementWithErrors.removeClass("is-invalid")
          elementWithErrors.removeClass("is-valid")

          const errorLabelSelector = `[data-field='${selector}']`
          $(errorLabelSelector).remove()
        }
      })
    })
  }
}
